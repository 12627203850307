@font-face {
    font-family: 'Euclid Circular A';
    src: url('../fonts/EuclidCircularA-Light.eot');
    src: local('Euclid Circular A Light'), local('EuclidCircularA-Light'),
    url('../fonts/EuclidCircularA-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/EuclidCircularA-Light.woff2') format('woff2'),
    url('../fonts/EuclidCircularA-Light.woff') format('woff'),
    url('../fonts/EuclidCircularA-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('../fonts/EuclidCircularA-Regular.eot');
    src: local('Euclid Circular A Regular'), local('EuclidCircularA-Regular'),
    url('../fonts/EuclidCircularA-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/EuclidCircularA-Regular.woff2') format('woff2'),
    url('../fonts/EuclidCircularA-Regular.woff') format('woff'),
    url('../fonts/EuclidCircularA-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('../fonts/EuclidCircularA-Medium.eot');
    src: local('Euclid Circular A Medium'), local('EuclidCircularA-Medium'),
    url('../fonts/EuclidCircularA-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/EuclidCircularA-Medium.woff2') format('woff2'),
    url('../fonts/EuclidCircularA-Medium.woff') format('woff'),
    url('../fonts/EuclidCircularA-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}


@font-face {
    font-family: 'Euclid Circular A';
    src: url('../fonts/EuclidCircularA-SemiBold.eot');
    src: local('Euclid Circular A SemiBold'), local('EuclidCircularA-SemiBold'),
    url('../fonts/EuclidCircularA-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/EuclidCircularA-SemiBold.woff2') format('woff2'),
    url('../fonts/EuclidCircularA-SemiBold.woff') format('woff'),
    url('../fonts/EuclidCircularA-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('../fonts/EuclidCircularA-Bold.eot');
    src: local('Euclid Circular A Bold'), local('EuclidCircularA-Bold'),
    url('../fonts/EuclidCircularA-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/EuclidCircularA-Bold.woff2') format('woff2'),
    url('../fonts/EuclidCircularA-Bold.woff') format('woff'),
    url('../fonts/EuclidCircularA-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

